<template>
  <div class="Guide">
    <Banner tit1="报考须知" tit2="Application Guide" url="1"></Banner>
    <div class="Notice">
      <h2>报考须知</h2>
      <el-card class="box_card">
        <h4>报考条件：</h4>
        <div>
          <p v-for="(item, index) in Notice[0]" :key="index" v-html="item"></p>
        </div>
        <h4>适合报考人群：</h4>
        <p v-for="item in Notice[1]" :key="item" v-html="item"></p>
        <h4>报考费用：</h4>
        <p v-for="item in Notice[2]" :key="item" v-html="item"></p>
      </el-card>
    </div>

    <div class="mode">
      <h2>报考方式：</h2>
      <el-card class="box_card">
        <h4 class="flex-col-center flex-row-around CE9A6C">
          <img
            class="icon"
            src="../../assets/images/guide/img2.png"
            alt
            srcset
          />
          在线报名
        </h4>
        <p>
          1.考生报名需在线注册并前往个人中心完善本人真实资料（<span class="font_red">信息真实性会影响证书的生成及证书发放问题，请如实填写！</span>）；
        </p>
        <p>
          2.考生在缴费后即可在正式评测开放时间内进行考试，正式考试开放时间在每月会定期开放，请考生注意时间公告；
        </p>
        <h4 class="flex-col-center flex-row-around CE9A6C">
          <img
            class="icon"
            src="../../assets/images/guide/img3.png"
            alt
            srcset
          />
          在线评测
        </h4>
        <p>
          1.<span class="font_red">参加考试时请考生使用佩戴摄像头的电脑进行考试，在正式考试时将有摄像头认证及监考，若发现违反考试规定，则视为作弊处理！</span>
        </p>
        <p>
          2.网培师测评的三个评测模块均采用在线答题方式，试题类型分为三种类型（单选题、多选题及判断题），请考生认真审题；
        </p>
        <p>
          3.每项评测试题数量为100~200道选择题，考试时长为90分钟或120分钟，考试结果达到60分以上（含60分）则视为评测通过；
        </p>
        <p>
          4.<span class="font_red">考试成绩不通过需要补考的考生，请在考试后的一个月内完成未通过的评测，如逾期平台则视为该考生放弃考试并取消补考机会！</span>
        </p>
        <h4 class="flex-col-center flex-row-around CE9A6C">
          <img
            class="icon"
            src="../../assets/images/guide/img6.png"
            alt
            srcset
          />
          获得证书
        </h4>
        <p>
          1.考生在评测完成后请耐心等待，平台将在7天内根据考生不同的成绩发放相应电子证书；
        </p>
        <p>
          2.由于纸质证书制作需要时间，制作完成后平台将根据用户填写的地址邮寄证书，请学员注意短信通知，及时查看物流信息；
        </p>
        <p>
          3.教育区块链银行会为已获得证书的学员颁发数字证书，详情请登录微信小程序“EDC学习银行”查看。
        </p>
      </el-card>
    </div>

    <div class="Notice">
      <h2>颁证条件：</h2>
      <el-card class="box_card">
        <p>网培师考试一共三个评测科目，“教学综合能力评测”“网培政策规范评测”“教学数字化能力评测”，学员需要通过不同的考试从而获得不同等级的证书，具体规则如下：</p>
        <p class="part_P">初级网培师资格证书：学员只需要通过“教学综合能力评测”和“网培政策规范评测”两科考试，即可获得初级网培师证书。</p>
        <p class="part_P">中级网培师资格证书：<span class="font_red">学员需要通过“教学综合能力评测”、“网培政策规范评测”和“教学数字化能力评测”三科考试，才可获得中级网培师资格证书。</span></p>
      </el-card>
    </div>

    <div class="seniorNotice">
      <h2>高级网培师报考须知</h2>
      <el-card class="box_card">
        <h4>报考条件：</h4>
        <p>1.考生具有国家承认本科以上学历；</p>
        <p>2.已获得中级网培师资格证书一年以上；</p>
        <p>3.深度了解教学方式及政策规范，热爱教学并取得过优异成就；</p>
        <p>4.熟悉现代化教育培训体系和知识，能熟练使用各类教学辅助工具；</p>
        <h4>适合报考人群：</h4>
        <p>1.从事在线教育行业多年并取得优异成绩的资深从业人员；</p>
        <p>2.在网络教学或培训的平台达成优异成就的资深从业人员；</p>
        <p>3.经常从事大型教学或讲座的职业人员；</p>
        <h4>报考费用：</h4>
        <p>
          1.申报高级网培师资格证需缴纳1200元，内包含专家评审费、管理服务费和证书制作费；
        </p>
        <p>2.评测费用是一次性付费，付费完成后不可退款，可开具正规发票；</p>
      </el-card>
    </div>

    <div class="mode">
      <h2>报考方式：</h2>
      <el-card class="box_card">
        <h4 class="flex-col-center flex-row-around CE9A6C">
          <img
            class="icon"
            src="../../assets/images/guide/img2.png"
            alt
            srcset
          />
          在线申报
        </h4>
        <p>1.申报高级网培师资格证需本人已获中级网培师资格证书1年以上；</p>
        <p>2.申报需上传1~3个本人优秀的教学视频；</p>
        <p>
          3.所在单位或平台的推荐信和个人已取得的其他辅助资料（如教学培训相关的荣誉证明证书等）；
        </p>
        <h4 class="flex-col-center flex-row-around CE9A6C">
          <img
            class="icon"
            src="../../assets/images/guide/img3.png"
            alt
            srcset
          />
          专家评审
        </h4>
        <p>
          提交申报材料后，网培师认证中心将定期组织专家评审会，通过对教学视频及其他材料进行评审，综合评价给予评审结果。
        </p>
        <h4 class="flex-col-center flex-row-around CE9A6C">
          <img
            class="icon"
            src="../../assets/images/guide/img6.png"
            alt
            srcset
          />
          获得证书
        </h4>
        <p>
          1.评审通过后，将由中国商业联合会颁发评审证明及高级网培师资格证书；
        </p>
        <p>2.同时教育区块链学习银行也将颁发相应的数字证书。</p>
      </el-card>
    </div>
  </div>
</template>
<script>
import Banner from './layout/Banner'
export default {
  data() {
    return {
      Notice: [
        [
          '1.考生需满18周岁；',
          '2.报考者必须拥有中专以上学历；',
          '3.考生应可以熟练的使用普通话；',
          '4.熟悉教学行业的政策规范；',
          '5.熟练使用制作课件的相关软件；',
        ],
        [
          '1.已经在从事教育行业的教师和培训师等人员；',
          '2.日常想兼职教学工作的人员；',
          '3.想通过网络教授职业技能或其他合法技能的人员；',
          '4.希望将来能从事在线教育行业工作并发展的人员；',
          '5.在线教育行业的专业管理人员、企业管理人员等。',
        ],
        [
          '1.评测费用一共需要2500元，内包含模拟练习资格费、证书制作费；',
          '2.评测费用是一次性付费，不存在单个模块付费用，付费完成后不可退款，可开具正规发票；',
          '3.<span class="font_red">付费完成后学员可获得模拟练习无限次使用机会和每个评测模块的一次补考机会。</span>',
        ],
      ],
    }
  },
  components: {
    Banner,
  },
}
</script>
<style lang="less" scoped>
.Guide {
  h2 {
    font-size: 26px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #000005;
    text-align: center;
  }
  h4 {
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #000005;
    text-align: center;
    margin: 15px 0px;
  }
  p {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 20px;
  }
  .CE9A6C {
    color: #ce9a6c;
    max-width: 150px;
    margin: 0px auto;
  }
  .box_card {
    width: 80vw;
    margin: 20px auto;
    padding: 10px 20px;
    div {
      max-width: 60vw;
      margin: 0px auto;
    }
    .part_P{
      margin-top: 12px;
    }
  }
  .Notice {
    padding: 20px 0px;
    width: 100%;
    background-color: #fbf8f5;
    border-radius: 0px 0px 5px 5px;
    /deep/.el-card__body {
      padding: 0px !important;
    }
  }
  .mode {
    padding: 20px 0px;
    /deep/.el-card__body {
      padding: 10px !important;
    }
    h4 {
      margin: 15px auto;
    }
    img {
      width: 50px;
      height: 50px;
    }
  }
  .seniorNotice {
    padding: 20px 0px;
    background: #fbf8f5;
    /deep/.el-card__body {
      padding: 0px !important;
    }
  }
}
</style>
